import { format } from 'date-fns';
import { type Locale, setDefaultOptions } from 'date-fns';
import {
  de,
  el,
  enIE,
  es,
  fr,
  it,
  nl,
  pl,
  pt,
  ptBR,
  ro,
  tr,
} from 'date-fns/locale';

import { SUPPORTED_LOCALE } from '../supportedLocales';

const dateFnsLocales: Record<SUPPORTED_LOCALE, Locale> = {
  de,
  el,
  en: enIE,
  es,
  fr,
  it,
  nl,
  pl,
  pt,
  ro,
  tr,
  'pt-BR': ptBR,
};

export const setDefaultDateFnsLocale = (locale: SUPPORTED_LOCALE) => {
  setDefaultOptions({ locale: dateFnsLocales[locale] });
};

export type DateFormat = 'short' | 'medium' | 'long' | 'full';

const dateFormats = {
  short: 'P',
  medium: 'PP',
  long: 'PPP',
  full: 'PPPP',
} as const;

export const formatDate = (
  date: string | Date | number | undefined | null,
  dateFormat: DateFormat = 'medium',
) => {
  if (date === undefined || date === null || date === '') {
    return '-';
  }

  return format(
    typeof date === 'string' ? new Date(date) : date,
    dateFormats[dateFormat],
  );
};
